export const ADJECTIVES = [
    "able", "admirable", "adventurous", "affectionate", "agile", "amiable", "analytical", "artistic", "astute", "attractive",
    "authentic", "authoritative", "balanced", "benevolent", "big_hearted", "bold", "buoyant", "calm", "candid", "captivating",
    "charming", "classic", "compassionate", "considerate", "content", "courageous", "cultured", "curious", "daring", "decisive",
    "dedicated", "dependable", "determined", "dynamic", "earnest", "elegant", "empathetic", "energetic", "engaging", "enlightened",
    "endearing", "enthusiastic", "ethical", "excellent", "exceptional", "exuberant", "fair_minded", "faithful", "fantastic", "fearless",
    "flexible", "flamboyant", "focused", "forgiving", "friendly", "genuine", "graceful", "gracious", "grateful", "harmonious",
    "helpful", "humorous", "imaginative", "impactful", "impressive", "independent", "innovative", "inspiring", "intelligent", "intuitive",
    "jovial", "kind_hearted", "laudable", "lively", "loving", "loyal", "magnetic", "majestic", "marvelous", "meaningful",
    "mindful", "modest", "motivated", "mystical", "natural", "nurturing", "observant", "open_hearted", "optimistic", "passionate",
    "patient", "perceptive", "persistent", "playful", "poised", "practical", "precise", "principled", "proactive", "productive",
    "profound", "pure", "radiant", "reassuring", "receptive", "refreshing", "reliable", "resourceful", "respectful", "responsible",
    "responsive", "revolutionary", "romantic", "sage", "sagacious", "sensitive", "serene", "sharp", "shining", "sincere",
    "skillful", "sociable", "sophisticated", "spirited", "spontaneous", "steadfast", "strategic", "stunning", "successful", "supportive",
    "sympathetic", "tenacious", "thoughtful", "tolerant", "trusting", "trustworthy", "unassuming", "unflappable", "unique", "uplifting",
    "versatile", "vibrant", "virtuous", "visionary", "vivid", "warm", "welcoming", "witty", "wonderful", "youthful", "zealous",
    "adaptable", "alert", "ambitious", "amiable", "analytical", "artistic", "benevolent", "big_hearted", "bright", "captivating",
    "charming", "classic", "caring", "cautious", "cheerful", "classic", "colorful", "composed", "compelling", "complex",
    "conscientious", "confident", "considerate", "constant", "creative", "crisp", "daring", "delightful", "dynamic", "eccentric",
    "effective", "effervescent", "elegant", "empowered", "engaging", "enlightened", "enriching", "esteemed", "ethical", "exciting",
    "exemplary", "exotic", "expressive", "fabulous", "faithful", "fantastic", "fearless", "flexible", "flourishing", "focused",
    "forgiving", "fortunate", "fun", "generous", "genuine", "gleaming", "grand", "gracious", "grateful", "harmonious", "hearty",
    "humorous", "idealistic", "impactful", "imaginative", "impressive", "invaluable", "inviting", "jubilant", "just", "keen",
    "kind_hearted", "lively", "loving", "magnificent", "memorable", "mindful", "motivated", "mystical", "natural", "noteworthy",
    "observant", "open_hearted", "optimistic", "outstanding", "peaceful", "perceptive", "persistent", "playful", "poised", "polished",
    "powerful", "practical", "precise", "proficient", "profound", "pure", "radiant", "receptive", "refreshing", "reliable",
    "resilient", "reverent", "robust", "savvy", "sensational", "sharp_witted", "sincere", "sophisticated", "spirited", "spontaneous",
    "steadfast", "stimulating", "strategic", "stunning", "supportive", "sympathetic", "tenacious", "thoughtful", "tolerant",
    "trustworthy", "unassuming", "unpretentious", "upbeat", "valued", "vibrant", "virtuous", "visionary", "wholesome", "wise",
    "witty", "wonderful", "youthful", "zealous", "accomplished", "admirable", "adorable", "altruistic", "ambiguous", "analytical",
    "artistic", "astonishing", "attractive", "authentic", "big_hearted", "bizarre", "blazing", "brilliant", "bright", "captivating",
    "charming", "classic", "caring", "cautious", "cheerful", "classic", "colorful", "composed", "compelling", "complex",
    "conscientious", "confident", "considerate", "constant", "creative", "crisp", "daring", "delightful", "dynamic", "eccentric",
    "effective", "effervescent", "elegant", "empowered", "engaging", "enlightened", "enriching", "esteemed", "ethical", "exciting",
    "exemplary", "exotic", "expressive", "fabulous", "faithful", "fantastic", "fearless", "flexible", "flourishing", "focused",
    "forgiving", "fortunate", "fun", "generous", "genuine", "gleaming", "grand", "gracious", "grateful", "harmonious", "hearty",
    "humorous", "idealistic", "impactful", "imaginative", "impressive", "invaluable", "inviting", "jubilant", "just", "keen",
    "kind_hearted", "lively", "loving", "magnificent", "memorable", "mindful", "motivated", "mystical", "natural", "noteworthy",
    "observant", "open_hearted", "optimistic", "outstanding", "peaceful", "perceptive", "persistent", "playful", "poised", "polished",
    "powerful", "practical", "precise", "proficient", "profound", "pure", "radiant", "receptive", "refreshing", "reliable",
    "resilient", "reverent", "robust", "savvy", "sensational", "sharp_witted", "sincere", "sophisticated", "spirited", "spontaneous",
    "steadfast", "stimulating", "strategic", "stunning", "supportive", "sympathetic", "tenacious", "thoughtful", "tolerant",
    "trustworthy", "unassuming", "unpretentious", "upbeat", "valued", "vibrant", "virtuous", "visionary", "wholesome", "wise",
    "witty", "wonderful", "youthful", "zealous", "accomplished", "admirable", "adorable", "altruistic", "ambiguous", "analytical",
    "artistic", "astonishing", "attractive", "authentic", "big_hearted", "bizarre", "blazing", "brilliant", "bright", "captivating",
    "charming", "classic", "caring", "cautious", "cheerful", "classic", "colorful", "composed", "compelling", "complex",
    "conscientious", "confident", "considerate", "constant", "creative", "crisp", "daring", "delightful", "dynamic", "eccentric",
    "effective", "effervescent", "elegant", "empowered", "engaging", "enlightened", "enriching", "esteemed", "ethical", "exciting",
    "exemplary", "exotic", "expressive", "fabulous", "faithful", "fantastic", "fearless", "flexible", "flourishing", "focused",
    "forgiving", "fortunate", "fun", "generous", "genuine", "gleaming", "grand", "gracious", "grateful", "harmonious", "hearty",
    "humorous", "idealistic", "impactful", "imaginative", "impressive", "invaluable", "inviting", "jubilant", "just", "keen",
    "kind_hearted", "lively", "loving", "magnificent", "memorable", "mindful", "motivated", "mystical", "natural", "noteworthy",
    "observant", "open_hearted", "optimistic", "outstanding", "peaceful", "perceptive", "persistent", "playful", "poised", "polished",
    "powerful", "practical", "precise", "proficient", "profound", "pure", "radiant", "receptive", "refreshing", "reliable",
    "resilient", "reverent", "robust", "savvy", "sensational", "sharp_witted", "sincere", "sophisticated", "spirited", "spontaneous",
    "steadfast", "stimulating", "strategic", "stunning", "supportive", "sympathetic", "tenacious", "thoughtful", "tolerant",
    "trustworthy", "unassuming", "unpretentious", "upbeat", "valued", "vibrant", "virtuous", "visionary", "wholesome", "wise",
    "witty", "wonderful", "youthful", "zealous", "accomplished", "admirable", "adorable", "altruistic", "ambiguous", "analytical",
    "artistic", "astonishing", "attractive", "authentic", "big_hearted", "bizarre", "blazing", "brilliant", "bright", "captivating",
    "charming", "classic", "caring", "cautious", "cheerful", "classic", "colorful", "composed", "compelling", "complex",
    "conscientious", "confident", "considerate", "constant", "creative", "crisp", "daring", "delightful", "dynamic", "eccentric",
    "effective", "effervescent", "elegant", "empowered", "engaging", "enlightened", "enriching", "esteemed", "ethical", "exciting",
    "exemplary", "exotic", "expressive", "fabulous", "faithful", "fantastic", "fearless", "flexible", "flourishing", "focused",
    "forgiving", "fortunate", "fun", "generous", "genuine", "gleaming", "grand", "gracious", "grateful", "harmonious", "hearty",
    "humorous", "idealistic", "impactful", "imaginative", "impressive", "invaluable", "inviting", "jubilant", "just", "keen",
    "kind_hearted", "lively", "loving", "magnificent", "memorable", "mindful", "motivated", "mystical", "natural", "noteworthy",
    "observant", "open_hearted", "optimistic", "outstanding", "peaceful", "perceptive", "persistent", "playful", "poised", "polished",
    "powerful", "practical", "precise", "proficient", "profound", "pure", "radiant", "receptive", "refreshing", "reliable",
    "resilient", "reverent", "robust", "savvy", "sensational", "sharp_witted", "sincere", "sophisticated", "spirited", "spontaneous",
    "steadfast", "stimulating", "strategic", "stunning", "supportive", "sympathetic", "tenacious", "thoughtful", "tolerant",
    "trustworthy", "unassuming", "unpretentious", "upbeat", "valued", "vibrant", "virtuous", "visionary", "wholesome", "wise",
    "witty", "wonderful", "youthful", "zealous", "accomplished", "admirable", "adorable", "altruistic", "ambiguous", "analytical",
    "artistic", "astonishing", "attractive", "authentic", "big_hearted", "bizarre", "blazing", "brilliant", "bright", "captivating",
    "charming", "classic", "caring", "cautious", "cheerful", "classic", "colorful", "composed", "compelling", "complex",
    "conscientious", "confident", "considerate", "constant", "creative", "crisp", "daring", "delightful", "dynamic", "eccentric",
    "effective", "effervescent", "elegant", "empowered", "engaging", "enlightened", "enriching", "esteemed", "ethical", "exciting",
    "exemplary", "exotic", "expressive", "fabulous", "faithful", "fantastic", "fearless", "flexible", "flourishing", "focused",
    "forgiving", "fortunate", "fun", "generous", "genuine", "gleaming", "grand", "gracious", "grateful", "harmonious", "hearty",
    "humorous", "idealistic", "impactful", "imaginative", "impressive", "invaluable", "inviting", "jubilant", "just", "keen",
    "kind_hearted", "lively", "loving", "magnificent", "memorable", "mindful", "motivated", "mystical", "natural", "noteworthy",
    "observant", "open_hearted", "optimistic", "outstanding", "peaceful", "perceptive", "persistent", "playful", "poised", "polished",
    "powerful", "practical", "precise", "proficient", "profound", "pure", "radiant", "receptive", "refreshing", "reliable",
    "resilient", "reverent", "robust", "savvy", "sensational", "sharp_witted", "sincere", "sophisticated", "spirited", "spontaneous",
    "steadfast", "stimulating", "strategic", "stunning", "supportive", "sympathetic", "tenacious", "thoughtful", "tolerant",
    "trustworthy", "unassuming", "unpretentious", "upbeat", "valued", "vibrant", "virtuous", "visionary", "wholesome", "wise",
    "witty", "wonderful", "youthful", "zealous"
];

export const NOUNS = [
    "ability", "academy", "account", "accident", "achievement", "act", "actor", "adventure", "advice", "agency",
    "agent", "airplane", "airport", "animal", "answer", "apartment", "apple", "area", "artist", "assignment",
    "athlete", "attitude", "authority", "award", "baby", "backpack", "balance", "ball", "band", "bank",
    "bar", "base", "basket", "bath", "beach", "beauty", "beer", "beginning", "belly", "benefit",
    "bicycle", "bird", "blood", "board", "book", "bottle", "box", "bridge", "brother", "build",
    "building", "bus", "butterfly", "cake", "camera", "car", "card", "care", "career", "castle",
    "cat", "ceiling", "center", "chair", "chance", "change", "child", "church", "city", "class",
    "clothes", "coach", "coffee", "college", "color", "community", "company", "computer", "condition", "connection",
    "contest", "contract", "conversation", "courage", "country", "course", "credit", "crime", "day", "decision",
    "desk", "disease", "district", "dog", "door", "dream", "drink", "drum", "duty", "education",
    "effect", "employee", "energy", "engine", "event", "example", "experience", "family", "farm", "father",
    "fear", "field", "film", "fish", "floor", "flower", "food", "football", "friend", "game",
    "garden", "girl", "goal", "government", "grape", "group", "guarantee", "guest", "gym", "habit",
    "hair", "hall", "health", "hill", "history", "home", "hospital", "house", "idea", "industry",
    "information", "interest", "internet", "island", "item", "job", "journey", "judge", "juice", "key",
    "kind", "knowledge", "language", "law", "leader", "library", "life", "line", "list", "location",
    "lunch", "market", "meeting", "member", "memory", "message", "method", "mile", "mind", "model",
    "moment", "money", "moon", "morning", "mother", "mountain", "movie", "music", "name", "nation",
    "nature", "network", "news", "night", "noise", "object", "office", "opinion", "organization", "owner",
    "page", "partner", "party", "path", "pen", "people", "period", "person", "place", "plan",
    "player", "point", "policy", "population", "position", "practice", "problem", "product", "program", "project",
    "public", "purpose", "quality", "question", "race", "radio", "rain", "reason", "record", "relationship",
    "reply", "report", "research", "restaurant", "result", "room", "safety", "school", "science", "season",
    "service", "ship", "shopping", "side", "sight", "situation", "sky", "society", "solution", "space",
    "sport", "stage", "statement", "station", "status", "student", "study", "style", "subject", "success",
    "support", "survey", "system", "table", "team", "teacher", "technology", "test", "theory", "time",
    "topic", "training", "transport", "travel", "tree", "trust", "type", "understanding", "unit", "university",
    "value", "village", "visit", "voice", "week", "window", "wish", "work", "world", "year",
    "youth", "zone", "activity", "actor", "advice", "airport", "artist", "baseball", "bathroom", "bicycle",
    "body", "book", "box", "brain", "brother", "cake", "camera", "campus", "car", "carpet",
    "cell", "chance", "change", "classroom", "coach", "concept", "conference", "connection", "conversation", "cook",
    "cost", "course", "credit", "dinner", "doctor", "document", "driver", "element", "engineer", "exercise",
    "family", "festival", "field", "flag", "floor", "football", "friend", "garden", "goal", "grandmother",
    "guitar", "hallway", "hand", "holiday", "hour", "impact", "instructor", "investment", "job", "journey",
    "language", "law", "library", "lunch", "manager", "market", "message", "month", "movement", "museum",
    "nature", "network", "news", "night", "office", "package", "park", "partner", "path", "pen",
    "performance", "phone", "pool", "population", "presentation", "process", "program", "project", "radio", "rate",
    "reception", "restaurant", "school", "season", "sense", "service", "session", "side", "soccer", "solution",
    "space", "speech", "sport", "stage", "student", "summer", "survey", "table", "technology", "test",
    "time", "track", "unit", "universe", "value", "venue", "view", "village", "voice", "way",
    "weather", "weekend", "window", "workshop", "yoga", "abroad", "actor", "advice", "airline", "artist",
    "backyard", "ball", "bank", "base", "beach", "board", "book", "branch", "bridge", "bus",
    "butter", "camp", "capital", "car", "castle", "cat", "chair", "chef", "chef", "chocolate",
    "church", "city", "class", "clock", "clothes", "coach", "color", "concept", "country", "credit",
    "crisis", "data", "day", "desk", "design", "distance", "document", "doctor", "door", "dream",
    "earth", "education", "effect", "element", "employee", "enjoyment", "event", "experience", "family", "file",
    "fish", "flower", "food", "form", "friend", "game", "garden", "gas", "goal", "government",
    "grade", "group", "growth", "hall", "hands", "health", "home", "hospital", "house", "improvement",
    "information", "instrument", "issue", "job", "journey", "key", "land", "language", "lecture", "level",
    "location", "lunch", "magic", "map", "market", "meeting", "method", "mile", "money", "movie",
    "music", "name", "nature", "network", "office", "option", "order", "organization", "page", "pair",
    "park", "pass", "pattern", "performance", "place", "plan", "player", "point", "policy", "position",
    "practice", "present", "price", "problem", "process", "project", "property", "purpose", "quality", "question",
    "rate", "region", "relationship", "report", "restaurant", "result", "room", "school", "service", "session",
    "side", "sign", "skill", "space", "stage", "state", "student", "study", "style", "subject",
    "success", "support", "system", "table", "team", "technology", "test", "theme", "time", "topic",
    "total", "track", "travel", "treatment", "type", "unit", "universe", "user", "value", "week",
    "work", "worker", "world", "year", "zone", "action", "adventure", "allergy", "art", "assignment",
    "basketball", "beverage", "briefcase", "calendar", "capital", "ceiling", "climate", "comment", "competition", "courage",
    "custom", "deadline", "department", "design", "device", "discussion", "district", "doctor", "element", "environment",
    "example", "family", "feature", "festival", "focus", "food", "goal", "guest", "holiday", "hope",
    "impact", "industry", "infrastructure", "invention", "job", "leader", "lesson", "library", "meeting", "model",
    "object", "opportunity", "organization", "outcome", "partner", "place", "plan", "player", "purpose", "recovery",
    "reputation", "risk", "schedule", "skill", "source", "standard", "strategy", "survey", "system", "theme",
    "total", "unit", "value", "variety", "venue", "victory", "view", "witness", "zone", "aesthetic",
    "activity", "advice", "agency", "apartment", "arena", "assignment", "association", "attraction", "award", "bar",
    "base", "biography", "body", "book", "building", "campus", "card", "catalog", "celebration", "class",
    "clinic", "collection", "conference", "connection", "contribution", "course", "data", "deadline", "description", "design",
    "destination", "diet", "door", "driver", "effect", "element", "employee", "encounter", "event", "facility",
    "feature", "field", "focus", "goal", "grocery", "hall", "hospital", "idea", "image", "information",
    "invention", "issue", "journal", "key", "lesson", "library", "location", "management", "market", "material",
    "meeting", "mode", "network", "option", "organization", "performance", "platform", "plan", "practice", "presentation",
    "priority", "process", "project", "property", "quality", "question", "range", "record", "role", "session",
    "service", "side", "solution", "state", "strategy", "student", "style", "system", "team", "test",
    "theory", "topic", "track", "type", "update", "value", "venture", "view", "voice", "world"
];